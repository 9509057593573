@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Barlow:wght@400&display=swap');

body {
  margin: 0;
  font-family: 'Barlow', 'Inter', 'Libre Baskerville', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Libre Baskerville';
  font-weight: 400;
  font-size: 50px;
}

h3 {
  font-family: 'Libre Baskerville';
  font-weight: 700;
  font-size: 28px;
}

p {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 25px;
}
